import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import BalanceCard from './api/endpoints/balanceCard';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

const BalanceCardContext = createContext({});

export function BalanceCardProvider({ children }) {
    const { t } = useTranslation(['translation', 'error']);

    const [serialNumber, setSerialNumber] = useState(null);
    const [balanceUpdates, setBalanceUpdates] = useState([]);
    const [balanceCard, setBalanceCard] = useState(null);
    const [events, setEvents] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (serialNumber !== null) {
            populateBalanceCard();
        }
    }, [serialNumber]);

    const populateBalanceCard = useCallback(() => {
        if (serialNumber !== null) {
            setLoading(true);

            BalanceCard.getBalanceUpdates(serialNumber, {}, false, t)
                .then((result) => {
                    if (result) {
                        setBalanceCard(result[0]?.balanceCard);

                        const newEvents = [];

                        result.forEach((event) => {
                            newEvents.push(event.event);

                            event.updates.map((update) => {
                                const products = {};

                                update.products.forEach((product) => {
                                    if (!Object.hasOwn(products, product.name)) {
                                        if (product.name === 'Balanskaart top-up') {
                                            products[product.name] = {
                                                id: product.id,
                                                amount: 1,
                                                total: parseFloat(update.amount)
                                            };
                                        } else {
                                            products[product.name] = {
                                                id: product.id,
                                                amount: 1,
                                                total: parseFloat(product.price)
                                            };
                                        }
                                    } else {
                                        products[product.name].amount++;
                                        products[product.name].total += parseFloat(product.price);
                                    }
                                });

                                update.products = products;

                                if (typeof update.date === 'object') {
                                    return update;
                                }

                                update.date = DateTime.fromISO(update.date);
                                return update;
                            });

                            event.updates.sort((a, b) => {
                                return b.date - a.date;
                            });
                        });

                        setEvents(newEvents);
                        setBalanceUpdates(result);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [serialNumber]);

    const requiresRegistration = useMemo(() => {
        let res = false;

        if (balanceCard === null || balanceCard?.registered) return res;

        events?.forEach((event) => {
            if (event.balanceCardRequiresRegistration) {
                res = true;
            }
        });

        return res;
    }, [events, balanceCard]);

    const memoedValue = useMemo(
        () => ({
            balanceUpdates,
            populateBalanceCard,
            serialNumber,
            setSerialNumber,
            loading,
            events,
            requiresRegistration
        }),
        [balanceUpdates, populateBalanceCard, serialNumber, setSerialNumber, loading, events, requiresRegistration]
    );

    return <BalanceCardContext.Provider value={memoedValue}>{children}</BalanceCardContext.Provider>;
}

BalanceCardProvider.propTypes = {
    children: PropTypes.object
};

export default function useBalanceCard() {
    return useContext(BalanceCardContext);
}
